import { Direction } from "types";

interface ArrowIconProps {
  direction?: Direction;
  className?: string;
}

export const ArrowIcon = ({
  direction = Direction.Right,
  className = "",
}: ArrowIconProps) => {
  const rotation = direction === Direction.Left ? "rotate-180" : "";

  return (
    <svg
      className={`w-6 h-6 ${rotation} ${className}`}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 18L15 12L9 6"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
