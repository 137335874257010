import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FlagIcon from "./LanguageIcon";
import StateIcon from "./StateIcon";
import { Language } from "types";

interface LanguageSwitcherProps {
  isMobileMenuOpen?: boolean;
}

export const LanguageSwitcher: React.FC<LanguageSwitcherProps> = ({
  isMobileMenuOpen,
}) => {
  const { t, i18n } = useTranslation();
  const [isLanguageDropdownOpen, setIsLanguageDropdownOpen] = useState(false);
  const toggleLanguageDropdown = () =>
    setIsLanguageDropdownOpen(!isLanguageDropdownOpen);

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("i18nextLng", lng);
    setIsLanguageDropdownOpen(false);
  };
  const { English, German, French, Spanish, Portuguese } = Language;

  useEffect(() => {
    if (!isMobileMenuOpen) {
      setIsLanguageDropdownOpen(false);
    }
  }, [isMobileMenuOpen]);

  return (
    <div className="relative w-40 block">
      <button
        onClick={toggleLanguageDropdown}
        className="flex items-center w-full rounded-xl py-3 pl-4 pr-3 bg-[#E3EFFF]"
      >
        <FlagIcon language={(i18n.language as Language) || English} />
        <span className="text-lg text-textPrimary ml-2 mr-auto">
          {t("Language")}
        </span>
        <StateIcon
          className={`transform transition-transform duration-300 ease-linear ${
            isLanguageDropdownOpen ? "rotate-180" : "rotate-0"
          }`}
        />
      </button>

      {isLanguageDropdownOpen && (
        <div className="absolute right-0 w-40 space-y-2 px-1 py-3 mt-2 bg-[#E3EFFF] rounded-xl shadow-lg z-50">
          <button
            className="language-button"
            onClick={() => changeLanguage(English)}
          >
            <FlagIcon language={English} />
            {t("English")}
          </button>
          <button
            className="language-button"
            onClick={() => changeLanguage(German)}
          >
            <FlagIcon language={German} />
            {t("German")}
          </button>
          <button
            className="language-button"
            onClick={() => changeLanguage(French)}
          >
            <FlagIcon language={French} />
            {t("French")}
          </button>
          <button
            className="language-button"
            onClick={() => changeLanguage(Spanish)}
          >
            <FlagIcon language={Spanish} />
            {t("Spanish")}
          </button>
          <button
            className="language-button"
            onClick={() => changeLanguage(Portuguese)}
          >
            <FlagIcon language={Portuguese} />
            {t("Portuguese")}
          </button>
        </div>
      )}
    </div>
  );
};
