import { Cards } from 'components/Cards';
import { Hero } from 'components/Hero';
import { QrCodeBlock } from 'components/QrCodeBlock';
import { ContactForm } from 'components/ContactForm';
import { PatternCards } from 'components/PatternCards';
import { FAQ } from 'components/FaqSection';
import { Abbreviation } from 'components/Abbreviation';

export const Content = () => {
  return (
    <main>
      <Hero />
      <QrCodeBlock />
      <PatternCards/>
      <Cards />
      <Abbreviation />
      <FAQ />
      <ContactForm />
    </main>
  );
};
