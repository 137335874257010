import React from "react";
import { Language } from "types";

interface FlagIconProps {
  language: Language;
}

const FlagIcon: React.FC<FlagIconProps> = ({ language }) => {
  switch (language) {
    case Language.English:
      return (
        <svg
          width="20"
          height="21"
          viewBox="0 0 20 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_11719_1134)">
            <path
              d="M10 20.5C15.5228 20.5 20 16.0228 20 10.5C20 4.97715 15.5228 0.5 10 0.5C4.47715 0.5 0 4.97715 0 10.5C0 16.0228 4.47715 20.5 10 20.5Z"
              fill="#F0F0F0"
            />
            <path
              d="M9.56543 10.5001H20.0002C20.0002 9.59749 19.8799 8.72312 19.6557 7.89136H9.56543V10.5001Z"
              fill="#D80027"
            />
            <path
              d="M9.56543 5.28266H18.5325C17.9203 4.28375 17.1376 3.40083 16.225 2.67395H9.56543V5.28266Z"
              fill="#D80027"
            />
            <path
              d="M10.0002 20.5001C12.3537 20.5001 14.5169 19.6866 16.2251 18.3262H3.77539C5.48359 19.6866 7.64676 20.5001 10.0002 20.5001Z"
              fill="#D80027"
            />
            <path
              d="M1.46797 15.7175H18.5325C19.0239 14.9156 19.4051 14.039 19.6557 13.1088H0.344727C0.595391 14.039 0.976523 14.9156 1.46797 15.7175Z"
              fill="#D80027"
            />
            <path
              d="M4.63219 2.06164H5.54348L4.69582 2.67746L5.01961 3.67391L4.17199 3.05809L3.32437 3.67391L3.60406 2.81309C2.85773 3.43477 2.20359 4.16313 1.66453 4.97469H1.95652L1.41695 5.36668C1.33289 5.50691 1.25227 5.64937 1.175 5.79395L1.43266 6.58695L0.951953 6.2377C0.832461 6.49086 0.723164 6.74973 0.624922 7.01398L0.908789 7.88773H1.95652L1.10887 8.50356L1.43266 9.5L0.585039 8.88418L0.0773047 9.25309C0.0264844 9.6616 0 10.0777 0 10.5H10C10 4.97719 10 4.32609 10 0.5C8.02453 0.5 6.18301 1.07305 4.63219 2.06164ZM5.01961 9.5L4.17199 8.88418L3.32437 9.5L3.64816 8.50356L2.80051 7.88773H3.84824L4.17199 6.89129L4.49574 7.88773H5.54348L4.69582 8.50356L5.01961 9.5ZM4.69582 5.59051L5.01961 6.58695L4.17199 5.97113L3.32437 6.58695L3.64816 5.59051L2.80051 4.97469H3.84824L4.17199 3.97824L4.49574 4.97469H5.54348L4.69582 5.59051ZM8.60656 9.5L7.75895 8.88418L6.91133 9.5L7.23512 8.50356L6.38746 7.88773H7.4352L7.75895 6.89129L8.0827 7.88773H9.13043L8.28277 8.50356L8.60656 9.5ZM8.28277 5.59051L8.60656 6.58695L7.75895 5.97113L6.91133 6.58695L7.23512 5.59051L6.38746 4.97469H7.4352L7.75895 3.97824L8.0827 4.97469H9.13043L8.28277 5.59051ZM8.28277 2.67746L8.60656 3.67391L7.75895 3.05809L6.91133 3.67391L7.23512 2.67746L6.38746 2.06164H7.4352L7.75895 1.0652L8.0827 2.06164H9.13043L8.28277 2.67746Z"
              fill="#0052B4"
            />
          </g>
          <defs>
            <clipPath id="clip0_11719_1134">
              <rect
                width="20"
                height="20"
                fill="white"
                transform="translate(0 0.5)"
              />
            </clipPath>
          </defs>
        </svg>
      );
    case Language.German:
      return (
        <svg
          width="20"
          height="21"
          viewBox="0 0 20 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_11719_1139)">
            <path
              d="M0.62207 13.9782C2.035 17.7862 5.70043 20.5 10.0001 20.5C14.2997 20.5 17.9652 17.7862 19.3781 13.9782L10.0001 13.1087L0.62207 13.9782Z"
              fill="#FFDA44"
            />
            <path
              d="M10.0001 0.5C5.70043 0.5 2.035 3.21375 0.62207 7.02176L10.0001 7.89129L19.3781 7.02172C17.9652 3.21375 14.2997 0.5 10.0001 0.5Z"
              fill="black"
            />
            <path
              d="M0.621992 7.02173C0.220039 8.10505 0 9.27677 0 10.5C0 11.7232 0.220039 12.8949 0.621992 13.9782H19.378C19.78 12.8949 20 11.7232 20 10.5C20 9.27677 19.78 8.10505 19.378 7.02173H0.621992Z"
              fill="#D80027"
            />
          </g>
          <defs>
            <clipPath id="clip0_11719_1139">
              <rect
                width="20"
                height="20"
                fill="white"
                transform="translate(0 0.5)"
              />
            </clipPath>
          </defs>
        </svg>
      );
    case Language.French:
      return (
        <svg
          width="20"
          height="21"
          viewBox="0 0 20 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_11719_1144)">
            <path
              d="M10 20.5C15.5228 20.5 20 16.0228 20 10.5C20 4.97715 15.5228 0.5 10 0.5C4.47715 0.5 0 4.97715 0 10.5C0 16.0228 4.47715 20.5 10 20.5Z"
              fill="#F0F0F0"
            />
            <path
              d="M20.0003 10.5C20.0003 6.20037 17.2865 2.53494 13.4785 1.12201V19.8781C17.2865 18.4651 20.0003 14.7997 20.0003 10.5Z"
              fill="#D80027"
            />
            <path
              d="M0 10.5C0 14.7997 2.71379 18.4651 6.52176 19.878V1.12201C2.71379 2.53494 0 6.20037 0 10.5Z"
              fill="#0052B4"
            />
          </g>
          <defs>
            <clipPath id="clip0_11719_1144">
              <rect
                width="20"
                height="20"
                fill="white"
                transform="translate(0 0.5)"
              />
            </clipPath>
          </defs>
        </svg>
      );
    case Language.Spanish:
      return (
        <svg
          width="20"
          height="21"
          viewBox="0 0 20 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_12058_989)">
            <path
              d="M18.6622 15.5C19.513 14.0291 20 12.3214 20 10.5C20 8.67857 19.513 6.97087 18.6622 5.5L1.33782 5.5C0.486972 6.97087 0 8.67857 0 10.5C0 12.3214 0.486971 14.0291 1.33782 15.5L18.6622 15.5Z"
              fill="#FFDA44"
            />
            <path
              d="M9.9961 20.5C6.04368 20.4986 2.62727 18.2043 1.00488 14.875L18.9943 14.875C17.3719 18.2043 13.9555 20.4987 10.0031 20.5C10.0019 20.5 10.0008 20.5 9.99959 20.5C9.99843 20.5 9.99726 20.5 9.9961 20.5Z"
              fill="#D80027"
            />
            <path
              d="M9.99959 0.5C6.04569 0.5 2.62775 2.79471 1.00488 6.125L18.9943 6.125C17.3714 2.79471 13.9535 0.5 9.99959 0.5Z"
              fill="#D80027"
            />
            <path
              d="M5.625 12.375H5V9.25H5.625V12.375ZM10.625 12.375H11.25V9.25H10.625V12.375Z"
              fill="#EEEEEE"
            />
            <path
              d="M4.76562 10.1875C4.72418 10.1875 4.68444 10.204 4.65514 10.2333C4.62584 10.2626 4.60938 10.3023 4.60938 10.3438C4.60938 10.3852 4.62584 10.4249 4.65514 10.4542C4.68444 10.4835 4.72418 10.5 4.76562 10.5H11.4844C11.5258 10.5 11.5656 10.4835 11.5949 10.4542C11.6242 10.4249 11.6406 10.3852 11.6406 10.3438C11.6406 10.3023 11.6242 10.2626 11.5949 10.2333C11.5656 10.204 11.5258 10.1875 11.4844 10.1875H4.76562ZM4.76562 11.125C4.72418 11.125 4.68444 11.1415 4.65514 11.1708C4.62584 11.2001 4.60938 11.2398 4.60938 11.2812C4.60938 11.3227 4.62584 11.3624 4.65514 11.3917C4.68444 11.421 4.72418 11.4375 4.76562 11.4375H5.85938C5.90082 11.4375 5.94056 11.421 5.96986 11.3917C5.99916 11.3624 6.01562 11.3227 6.01562 11.2812C6.01562 11.2398 5.99916 11.2001 5.96986 11.1708C5.94056 11.1415 5.90082 11.125 5.85938 11.125H4.76562ZM10.3906 11.125C10.3492 11.125 10.3094 11.1415 10.2801 11.1708C10.2508 11.2001 10.2344 11.2398 10.2344 11.2812C10.2344 11.3227 10.2508 11.3624 10.2801 11.3917C10.3094 11.421 10.3492 11.4375 10.3906 11.4375H11.4844C11.5258 11.4375 11.5656 11.421 11.5949 11.3917C11.6242 11.3624 11.6406 11.3227 11.6406 11.2812C11.6406 11.2398 11.6242 11.2001 11.5949 11.1708C11.5656 11.1415 11.5258 11.125 11.4844 11.125H10.3906Z"
              fill="#D80027"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.65625 7.0625C7.38281 7.0625 7.14844 7.25781 7.07031 7.49219L6.875 7.45312C6.52344 7.45312 6.25 7.72656 6.25 8.07812C6.25 8.42969 6.52344 8.70312 6.875 8.70312C7.14844 8.70312 7.38281 8.54688 7.46094 8.27344C7.57486 8.31453 7.69827 8.32165 7.81617 8.29391C7.93406 8.26617 8.04135 8.20477 8.125 8.11719C8.20865 8.20477 8.31594 8.26617 8.43383 8.29391C8.55173 8.32165 8.67514 8.31453 8.78906 8.27344C8.82697 8.39175 8.89927 8.49609 8.99674 8.57312C9.09422 8.65015 9.21244 8.69638 9.33631 8.70592C9.46018 8.71545 9.58408 8.68785 9.69219 8.62664C9.8003 8.56543 9.88772 8.47339 9.94328 8.36227C9.99884 8.25115 10.02 8.12599 10.0041 8.00277C9.98823 7.87955 9.93597 7.76387 9.85402 7.6705C9.77207 7.57712 9.66415 7.51028 9.54404 7.47852C9.42393 7.44677 9.29708 7.45152 9.17969 7.49219C9.14201 7.39092 9.07873 7.30114 8.99601 7.23162C8.91329 7.1621 8.81396 7.11522 8.70771 7.09555C8.60147 7.07587 8.49193 7.08408 8.3898 7.11936C8.28768 7.15465 8.19644 7.21581 8.125 7.29688C8.00781 7.14062 7.85156 7.0625 7.65625 7.0625ZM7.65625 7.375C7.85156 7.375 7.96875 7.53125 7.96875 7.6875C7.96875 7.88281 7.85156 8 7.65625 8C7.5 8 7.34375 7.88281 7.34375 7.6875C7.34375 7.53125 7.5 7.375 7.65625 7.375ZM8.59375 7.375C8.78906 7.375 8.90625 7.53125 8.90625 7.6875C8.90625 7.88281 8.78906 8 8.59375 8C8.4375 8 8.28125 7.88281 8.28125 7.6875C8.28125 7.53125 8.4375 7.375 8.59375 7.375ZM6.875 7.76562L7.03125 7.80469L7.1875 8.11719C7.1875 8.27344 7.03125 8.39062 6.875 8.39062C6.71875 8.39062 6.5625 8.27344 6.5625 8.07812C6.5625 7.92188 6.71875 7.76562 6.875 7.76562ZM9.375 7.76562C9.57031 7.76562 9.6875 7.92188 9.6875 8.07812C9.6875 8.27344 9.57031 8.39062 9.375 8.39062C9.21875 8.39062 9.0625 8.27344 9.0625 8.11719L9.21875 7.80469L9.375 7.76562ZM8.125 13.3125C9.16053 13.3125 10 12.7529 10 12.0625C10 11.6892 9.75449 11.354 9.36523 11.125H10V9.25H8.125V10.8125C7.08947 10.8125 6.25 11.3721 6.25 12.0625C6.25 12.7529 7.08947 13.3125 8.125 13.3125Z"
              fill="#EEEEEE"
            />
            <path d="M7.8125 6.75H8.4375V8H7.8125V6.75Z" fill="#FF9811" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.6875 8.625L9.375 8.9375H6.875L6.5625 8.625C6.5625 8.11719 7.26562 7.6875 8.125 7.6875C8.98438 7.6875 9.6875 8.11719 9.6875 8.625ZM5.3125 8C5.48509 8 5.625 8.13991 5.625 8.3125V8.625C5.625 8.79759 5.48509 8.9375 5.3125 8.9375C5.13991 8.9375 5 8.79759 5 8.625V8.3125C5 8.13991 5.13991 8 5.3125 8ZM10.9375 8C11.1101 8 11.25 8.13991 11.25 8.3125V8.625C11.25 8.79759 11.1101 8.9375 10.9375 8.9375C10.7649 8.9375 10.625 8.79759 10.625 8.625V8.3125C10.625 8.13991 10.7649 8 10.9375 8ZM8.125 11.125V12.0625C8.125 12.3111 8.22377 12.5496 8.39959 12.7254C8.5754 12.9012 8.81386 13 9.0625 13C9.31114 13 9.5496 12.9012 9.72541 12.7254C9.90123 12.5496 10 12.3111 10 12.0625V11.125H9.0625H8.125ZM8.125 11.125H6.25V9.25H8.125V11.125ZM9.45312 9.95312C9.45312 9.73739 9.27824 9.5625 9.0625 9.5625C8.84676 9.5625 8.67188 9.73739 8.67188 9.95312V10.4219C8.67188 10.6376 8.84676 10.8125 9.0625 10.8125C9.27824 10.8125 9.45312 10.6376 9.45312 10.4219V9.95312Z"
              fill="#D80027"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5 8.625H5.625C5.79759 8.625 5.9375 8.76491 5.9375 8.9375C5.9375 9.11009 5.79759 9.25 5.625 9.25H5C4.82741 9.25 4.6875 9.11009 4.6875 8.9375C4.6875 8.76491 4.82741 8.625 5 8.625ZM10.625 8.625H11.25C11.4226 8.625 11.5625 8.76491 11.5625 8.9375C11.5625 9.11009 11.4226 9.25 11.25 9.25H10.625C10.4524 9.25 10.3125 9.11009 10.3125 8.9375C10.3125 8.76491 10.4524 8.625 10.625 8.625ZM5.625 12.375H5C4.82741 12.375 4.6875 12.5149 4.6875 12.6875C4.6875 12.8601 4.82741 13 5 13H5.625C5.79759 13 5.9375 12.8601 5.9375 12.6875C5.9375 12.5149 5.79759 12.375 5.625 12.375ZM10.625 12.375H11.25C11.4226 12.375 11.5625 12.5149 11.5625 12.6875C11.5625 12.8601 11.4226 13 11.25 13H10.625C10.4524 13 10.3125 12.8601 10.3125 12.6875C10.3125 12.5149 10.4524 12.375 10.625 12.375ZM6.25 11.125V12.0625C6.25 12.375 6.40625 12.6094 6.60156 12.8047L6.79688 12.5703L6.99219 12.9609C7.1205 12.9924 7.2545 12.9924 7.38281 12.9609L7.57812 12.5703L7.77344 12.8047C8.00781 12.6094 8.125 12.375 8.125 12.0625V11.125H7.77344L7.57812 11.4375L7.38281 11.125H6.99219L6.79688 11.4375L6.60156 11.125H6.25ZM6.5625 9.875V9.5625H7.8125V9.875H7.5V10.5H7.8125V10.8125H6.5625V10.5H6.875V9.875H6.5625ZM9.375 8.9375H6.875V9.25H9.375V8.9375Z"
              fill="#FF9811"
            />
            <path
              d="M6.60156 11.125V12.8047C6.71825 12.8857 6.85182 12.9391 6.99219 12.9609V11.125H6.60156ZM7.38281 11.125V12.9609C7.52318 12.9391 7.65675 12.8857 7.77344 12.8047V11.125H7.38281Z"
              fill="#D80027"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.26562 8.625C7.39507 8.625 7.5 8.52007 7.5 8.39062C7.5 8.26118 7.39507 8.15625 7.26562 8.15625C7.13618 8.15625 7.03125 8.26118 7.03125 8.39062C7.03125 8.52007 7.13618 8.625 7.26562 8.625ZM8.125 8.625C8.25444 8.625 8.35938 8.52007 8.35938 8.39062C8.35938 8.26118 8.25444 8.15625 8.125 8.15625C7.99556 8.15625 7.89062 8.26118 7.89062 8.39062C7.89062 8.52007 7.99556 8.625 8.125 8.625ZM9.21875 8.39062C9.21875 8.52007 9.11382 8.625 8.98438 8.625C8.85493 8.625 8.75 8.52007 8.75 8.39062C8.75 8.26118 8.85493 8.15625 8.98438 8.15625C9.11382 8.15625 9.21875 8.26118 9.21875 8.39062Z"
              fill="#FFDA44"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.125 11.75C8.47018 11.75 8.75 11.4702 8.75 11.125C8.75 10.7798 8.47018 10.5 8.125 10.5C7.77982 10.5 7.5 10.7798 7.5 11.125C7.5 11.4702 7.77982 11.75 8.125 11.75ZM11.25 13H10.625C10.4524 13 10.3125 13.1399 10.3125 13.3125C10.3125 13.4851 10.4524 13.625 10.625 13.625H11.25C11.4226 13.625 11.5625 13.4851 11.5625 13.3125C11.5625 13.1399 11.4226 13 11.25 13ZM5 13H5.625C5.79759 13 5.9375 13.1399 5.9375 13.3125C5.9375 13.4851 5.79759 13.625 5.625 13.625H5C4.82741 13.625 4.6875 13.4851 4.6875 13.3125C4.6875 13.1399 4.82741 13 5 13Z"
              fill="#0052B4"
            />
          </g>
          <defs>
            <clipPath id="clip0_12058_989">
              <rect
                width="20"
                height="20"
                fill="white"
                transform="translate(0 0.5)"
              />
            </clipPath>
          </defs>
        </svg>
      );
    case Language.Portuguese:
      return (
        <svg
          width="20"
          height="21"
          viewBox="0 0 20 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_12058_1036)">
            <path
              d="M0 10.5C0 14.7996 2.71375 18.4651 6.52168 19.878L7.39129 10.5L6.52168 1.12201C2.71375 2.53498 0 6.20037 0 10.5Z"
              fill="#6DA544"
            />
            <path
              d="M19.9998 10.5C19.9998 4.97719 15.5226 0.5 9.9998 0.5C8.7766 0.5 7.60484 0.720039 6.52148 1.12199V19.878C7.60484 20.28 8.7766 20.5 9.9998 20.5C15.5226 20.5 19.9998 16.0228 19.9998 10.5Z"
              fill="#D80027"
            />
            <path
              d="M6.52219 13.9782C8.44317 13.9782 10.0004 12.421 10.0004 10.5C10.0004 8.57899 8.44317 7.02173 6.52219 7.02173C4.60121 7.02173 3.04395 8.57899 3.04395 10.5C3.04395 12.421 4.60121 13.9782 6.52219 13.9782Z"
              fill="#FFDA44"
            />
            <path
              d="M4.56543 8.76086V10.9348C4.56543 12.0153 5.44137 12.8913 6.52195 12.8913C7.60254 12.8913 8.47848 12.0154 8.47848 10.9348V8.76086H4.56543Z"
              fill="#D80027"
            />
            <path
              d="M6.52133 11.587C6.16172 11.587 5.86914 11.2944 5.86914 10.9348V10.0652H7.17348V10.9348C7.17348 11.2944 6.8809 11.587 6.52133 11.587Z"
              fill="#F0F0F0"
            />
          </g>
          <defs>
            <clipPath id="clip0_12058_1036">
              <rect
                width="20"
                height="20"
                fill="white"
                transform="translate(0 0.5)"
              />
            </clipPath>
          </defs>
        </svg>
      );
    default:
      return null;
  }
};

export default FlagIcon;
