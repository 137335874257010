import { AppStoreIcon } from "components/AppDownload/AppStoreIcon";
import { GooglePlayIcon } from "components/AppDownload/GooglePlayIcon";

interface Props {
  inlineButtons?: boolean;
}

export const AppDownload = ({ inlineButtons }: Props) => {
  return (
    <section id="download" className="scroll-mt-10">
      <div
        className={inlineButtons ? "flex items-center gap-x-4" : "grid gap-y-4"}
      >
        <a
          href="https://apps.apple.com/us/app/crochetapp-row-counter-app/id1545599414"
          target="_blank"
          rel="noreferrer"
          aria-label="App store"
        >
          <AppStoreIcon />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=lv.crochet.app"
          target="_blank"
          rel="noreferrer"
          aria-label="Google play"
        >
          <GooglePlayIcon />
        </a>
      </div>
    </section>
  );
};
