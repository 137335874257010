import crochetImage from "assets/device.webp";
import { ContentContainer } from "components/Container";
import { SliderBlock } from "components/Slider";
import { useTranslation } from "react-i18next";

export const Hero = () => {
  const { t } = useTranslation();

  return (
    <section className="bg-heroBackground overflow-hidden">
      <ContentContainer>
        <div className="flex items-center flex-wrap-reverse md:grid md:grid-cols-2 relative">
          <div className="w-full">
            <h1 className="font-600 lg:max-w-[470px] text-primary text-3xl md:text-[40px] lg:text-6xl">
              {t("hero.title")}
            </h1>
            <SliderBlock />
          </div>
          <div className="md:mx-auto">
            <img
              className="mb-5 max-w-full md:max-w-[430px] lg:max-w-full"
              src={crochetImage}
              alt="Mobile with crochet app on screen"
            />
          </div>
        </div>
      </ContentContainer>
    </section>
  );
};
