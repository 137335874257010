import i18next from 'i18next';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { createBrowserHistory } from 'history';
import LanguageDetector from 'i18next-browser-languagedetector';

const history = createBrowserHistory();
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: false,
    supportedLngs: ['en', 'de', 'fr', 'es', 'pt'],
    lng: localStorage.getItem("i18nextLng") || "en",
    resources: {
      en: {
        translation: require('./locales/en.json')
      },
      de: {
        translation: require('./locales/de.json')
      },
      fr: {
        translation: require('./locales/fr.json')
      },
      es: {
        translation: require('./locales/es.json')
      },
      pt: {
        translation: require('./locales/pt.json')
      }
    },
    interpolation: {
      escapeValue: false
    },
    detection: {
      order: ['localStorage', 'navigator'],
      caches: ['localStorage']
    },
  });

  document.documentElement.setAttribute(
    "lang",
    localStorage.getItem("i18nextLng") || "en"
  );

  i18next.on('languageChanged', (lng) => {
    document.documentElement.setAttribute('lang', lng);
    localStorage.setItem("i18nextLng", lng);
    if (lng === 'en') {
      history.push('/');
    } else {
      history.push(`/${lng}`);
    }
  });
  
export default i18n;
