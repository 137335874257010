import teddyBearImage from "assets/icon-teddy-bear.webp";
import { AppDownload } from "components/AppDownload";
import { ContentContainer } from "components/Container";
import { Logo } from "components/Logo";
import { useTranslation } from "react-i18next";

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="bg-purple lg:bg-white mt-24 mb-20 lg:mt-52">
      <ContentContainer>
        <div className="grid text-center relative lg:bg-footer-background lg:bg-no-repeat lg:bg-contain lg:bg-center py-8 md:py-16">
          <img
            className="absolute -top-20 md:-top-38 lg:-top-32 right-0 h-auto w-32 md:w-36 lg:w-56"
            src={teddyBearImage}
            alt="teddy bear toy"
          />
          <ContentContainer>
            <Logo />
          </ContentContainer>
          <p className="font-500 text-2xl lg:text-4xl lg:leading-12 max-w-4xl mt-9 mx-auto">
            {t("footer.appDescription")}
          </p>
          <div className="flex justify-center mt-10">
            <AppDownload inlineButtons />
          </div>
          <div className="mb-4" />
        </div>
      </ContentContainer>
    </footer>
  );
};
