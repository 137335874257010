import { ContentContainer } from "components/Container";
import { videos } from "data/video";
import { NavigationIcon } from "./NavigationIcon";
import { analytics, logEvent } from "firebaseConfig";
import { useEffect, useState } from "react";
import { ArrowIcon } from "./ArrowIcon";
import { Direction } from "types";
import { useTranslation } from "react-i18next";

interface Video {
  id: string;
  title: string;
  description: string;
}

export const Abbreviation = () => {
  const { t } = useTranslation();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const LARGE_SCREEN_ITEMS = 3;
  const MEDIUM_SCREEN_ITEMS = 2;
  const SMALL_SCREEN_ITEMS = 1;
  const YOUTUBE_CHANNEL = "https://www.youtube.com/@CrochetApp/videos";

  const itemsToShow =
    windowWidth >= 1024
      ? LARGE_SCREEN_ITEMS
      : windowWidth >= 768
      ? MEDIUM_SCREEN_ITEMS
      : SMALL_SCREEN_ITEMS;
  const step = itemsToShow;

  const handleVideoClick = (video: Video) => {
    if (analytics) {
      logEvent(analytics, "video_open", {
        video_title: video.title,
      });
    }
  };

  const goNext = () => {
    if (currentIndex + step < videos.length) {
      setCurrentIndex(currentIndex + step);
    }
  };

  const goPrev = () => {
    if (currentIndex - step >= 0) {
      setCurrentIndex(currentIndex - step);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section id="abbreviation" className="mt-20 lg:mt-36 scroll-mt-10">
      <ContentContainer>
        <div className="flex justify-between items-center">
          <h2 className="max-w-78 md:max-w-full title-text-style">
            {t("abbreviation")}
          </h2>
          <a
            href={YOUTUBE_CHANNEL}
            target="_blank"
            rel="noopener noreferrer"
            className="flex text-xl gap-x-2 text-textPurple font-600 items-center"
          >
            <p>{t("view_all")}</p>
            <NavigationIcon className="text-textPurple" />
          </a>
        </div>
        <p className="subtitle-text-style max-w-812px mt-5 lg:mt-6">
          {t("explore_abbreviations")}
        </p>
        <div
          className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 lg:gap-8 mt-20`}
        >
          {videos
            .slice(currentIndex, currentIndex + itemsToShow)
            .map((video) => (
              <a
                key={video.id}
                href={`https://www.youtube.com/watch?v=${video.id}`}
                target="_blank"
                rel="noopener noreferrer"
                className="block"
                onClick={() => handleVideoClick(video)}
              >
                <iframe
                  title={t("abbreviations_video_title")}
                  width="100%"
                  height="240px"
                  className="rounded-[40px]"
                  src={`https://www.youtube.com/embed/${video.id}`}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
                <div className="flex justify-between items-start mt-8">
                  <h2 className="line-clamp-2 text-2xl font-semibold w-10/12">
                    {video.title}
                  </h2>
                  <NavigationIcon />
                </div>
                <p className="line-clamp-3 text-textSecondaryGrey text-base mt-4">
                  {video.description}
                </p>
              </a>
            ))}
        </div>
        <div className="flex justify-center gap-6 mt-16">
          <button
            onClick={goPrev}
            disabled={currentIndex === 0}
            className={`w-[60px] h-[60px] flex items-center justify-center rounded-full ${
              currentIndex === 0
                ? "bg-lightGrey"
                : "bg-lightGrey hover:bg-activeVideoButton"
            }`}
          >
            <ArrowIcon
              direction={Direction.Left}
              className={`${
                currentIndex === 0
                  ? "text-inactiveVideoArrow"
                  : "text-textPrimary"
              }`}
            />
          </button>
          <button
            onClick={goNext}
            disabled={currentIndex + itemsToShow >= videos.length}
            className={`w-[60px] h-[60px] flex items-center justify-center rounded-full ${
              currentIndex + itemsToShow >= videos.length
                ? "bg-lightGrey"
                : "bg-lightGrey hover:bg-activeVideoButton"
            }`}
          >
            <ArrowIcon
              direction={Direction.Right}
              className={`${
                currentIndex + itemsToShow >= videos.length
                  ? "text-inactiveVideoArrow"
                  : "text-textPrimary"
              }`}
            />
          </button>
        </div>
      </ContentContainer>
    </section>
  );
};
