import qrcodeIcon from 'assets/qr-code.webp';
import users from 'assets/users.webp';
import { AppDownload } from 'components/AppDownload/index';
import { ContentContainer } from 'components/Container/index';

export const QrCodeBlock = () => {
  return (
    <section className="rounded-b-[40px] lg:rounded-b-[80px] pt-10 pb-5 md:py-12 bg-heroBackground">
      <ContentContainer>
        <div className="md:grid grid-cols-3 items-center">
          <div className="flex-col text-center w-32 lg:w-40">
            <div className="flex items-center flex-grow">
              <img
                className="w-32 h-32 lg:w-40 lg:h-40 mr-6"
                src={qrcodeIcon}
                alt="qr code"
              />
              <AppDownload />
            </div>
            <div className="text-xs lg:text-sm">
              <span className='whitespace-nowrap'>
                QR code to
                <strong> Crochet app</strong>
              </span>
            </div>
          </div>
          <div className="hidden md:block w-px ml-32 bg-dividerGrey h-40" />
          <div className="block md:hidden h-px mt-10 mb-8 bg-dividerGrey w-[100%]" />
          <div className="font-500 text-5xl md:-ml-10 lg:-ml-24 lg:text-14 text-primary whitespace-nowrap">
            500 000+
            <div className="flex items-center font-300 text-sm md:text-xl xl:text-2xl mt-3 text-textLightGrey">
              <img
                src={users}
                alt="user avatars"
                className="w-auto h-8 md:h-10"
              />
              <span className="ml-2">users</span>
            </div>
          </div>
        </div>
      </ContentContainer>
    </section>
  );
};
