import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface FormData {
  name: string;
  email: string;
  message: string;
}

interface SendEmailOptions {
  formData: FormData;
  setFormData: (data: FormData) => void;
  successIcon: JSX.Element;
  errorIcon: JSX.Element;
}

export const sendEmail = async ({
  formData,
  setFormData,
  successIcon,
  errorIcon
}: SendEmailOptions) => {
  if (!formData.name || !formData.email || !formData.message) {
    toast.error("All fields are required.", {
      className: "custom-toast-class",
      bodyClassName: "custom-toast-body",
      icon: errorIcon,
    });
    return;
  }

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(formData.email)) {
    toast.error("Please enter a valid email address.", {
      className: "custom-toast-class",
      bodyClassName: "custom-toast-body",
      icon: errorIcon,
    });
    return;
  }

  try {
    await emailjs.send(
      process.env.REACT_APP_EMAILJS_SERVICE_ID || "",
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID || "",
      {
        Name: formData.name,
        Email: formData.email,
        Information: formData.message,
      },
      process.env.REACT_APP_EMAILJS_USER_ID || ""
    );

    toast.success("Thank you for sharing! Your email has been successfully sent!", {
      className: "custom-toast-class",
      bodyClassName: "custom-toast-body",
      icon: successIcon,
    });
    
    setFormData({ name: "", email: "", message: "" });
  } catch (error) {
    console.error("Failed to send email:", error);
    toast.error("Oops! Something went wrong, please try again.", {
      className: "custom-toast-class",
      bodyClassName: "custom-toast-body",
      icon: errorIcon,
    });
  }
};
