import { useTranslation } from 'react-i18next';

interface CardEntry {
  src: string;
  alt: string;
  background: string;
  border: string;
  textColor: string;
  titleKey: string;
  textKey: string;
}

interface Props {
  card: CardEntry;
}

export const Card = ({ card }: Props) => {
  const { t } = useTranslation();

  return (
    <div
      className={`pt-3 pb-6 md:pb-10 px-6 ${card.background} rounded-50px border-thin ${card.border}`}
    >
      <img className="w-24 h-24 md:w-32 md:h-32" src={card.src} alt={card.alt} />
      <h3 className="text-2xl max-w-60 md:max-w-full md:text-3xl font-500">
        {t(card.titleKey)}
      </h3>
      <p className={`text-base md:text-lg ${card.textColor} mt-3 leading-6`}>
        {t(card.textKey)}
      </p>
    </div>
  );
};
