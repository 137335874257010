import catBeanieImage from "assets/patternImage/catBeanie.webp";
import flowerCollarImage from "assets/patternImage/flowerCollar.webp";
import miloPumpkinImage from "assets/patternImage/miloPumpkin.webp";
import raffaHatImage from "assets/patternImage/raffaHat.webp";
import tangerineImage from "assets/patternImage/tangerine.webp";
import grumpyCatImage from "assets/patternImage/grumpyCat.webp";
import duckBabyRattleImage from "assets/patternImage/duckBabyRattle.webp";
import dogBoneImage from "assets/patternImage/dogBone.webp";

export const cards = [
  {
    src: catBeanieImage,
    alt: "Features cute cat ears and a colorful striped design",
  },
  {
    src: miloPumpkinImage,
    alt: "Cozy pumpkin-shaped house knitting pattern for a stuffed toy.",
  },
  {
    src: raffaHatImage,
    alt: "Wide-brimmed raffia hat knitting pattern",
  },
  {
    src: flowerCollarImage,
    alt: "Colorful floral collar knitting pattern for pets",
  },
  {
    src: tangerineImage,
    alt: "Cute and cuddly tangerine knitting pattern",
  },
  {
    src: grumpyCatImage,
    alt: "Playful grumpy cat knitting pattern",
  },
  {
    src: duckBabyRattleImage,
    alt: "Soft and squeaky duck rattle knitting pattern for babies",
  },
  {
    src: dogBoneImage,
    alt: "Classic dog bone knitting pattern",
  },
];
