type NavigationIconProps = {
    className?: string;
  };
  
  export const NavigationIcon = ({ className = "" }: NavigationIconProps) => {
    return (
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <path
          d="M7 17.6042L17 7.60425M17 7.60425H7M17 7.60425V17.6042"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };
  