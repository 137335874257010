import React from 'react';
import { FAQIconType } from 'types';

interface FAQIconProps {
    type: FAQIconType;
}

const FAQIcon: React.FC<FAQIconProps> = ({ type }) => {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {type === FAQIconType.Plus ? (
        <path
          d="M16 11.0045V22.2045M10.4 16.6045H21.6M30 16.6045C30 24.3365 23.732 30.6045 16 30.6045C8.26801 30.6045 2 24.3365 2 16.6045C2 8.87251 8.26801 2.60449 16 2.60449C23.732 2.60449 30 8.87251 30 16.6045Z"
          stroke="#A462C4"
          strokeWidth="2.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      ) : (
        <path
          d="M10.4 16.6045H21.6M30 16.6045C30 24.3365 23.732 30.6045 16 30.6045C8.26801 30.6045 2 24.3365 2 16.6045C2 8.87251 8.26801 2.60449 16 2.60449C23.732 2.60449 30 8.87251 30 16.6045Z"
          stroke="#A462C4"
          strokeWidth="2.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      )}
    </svg>
  );
};

export default FAQIcon;
