import { Card } from 'components/Cards/Card';
import { ContentContainer } from 'components/Container';
import { cards } from 'data/cards';
import { Col, Row } from 'react-grid-system';
import { useTranslation } from 'react-i18next';

export const Cards = () => {
  const { t } = useTranslation();

  return (
    <section id="features" className="mt-20 lg:mt-36 scroll-mt-10">
      <ContentContainer>
        <Row>
          <Col xs={12}>
            <h2 className="max-w-78 md:max-w-full title-text-style">
              {t("simple_crochet_app")}
            </h2>
          </Col>
          <Col xs={12}>
            <p className="subtitle-text-style max-w-812px mt-5 lg:mt-9">
              {t("crochet_app_description")}
            </p>
          </Col>
        </Row>
        <div className="mt-10 lg:mt-20 grid grid-cols-1 lg:grid-cols-2 gap-5 lg:gap-10">
          {cards.map((card) => (
            <Card key={card.src} card={card} />
          ))}
        </div>
      </ContentContainer>
    </section>
  );
};
