export const mobileMenuStyles = {
    button: "z-40 flex flex-col justify-around w-6 h-6 transform transition-transform duration-300 lg:hidden",
    buttonOpen: "fixed top-5 md:top-7 right-6",
    spanBase: "block w-6 h-0.5 transition-transform duration-300",
    spanOpenPrimary: "rotate-45 translate-y-1.5 bg-textPrimary",
    spanOpenSecondary: "-rotate-45 -translate-y-1.5 bg-textPrimary",
    spanClosed: "bg-textSecondaryGrey",
    menuContainer: "fixed right-0 top-0 h-full bg-[#F0F6FF] z-30 transition-transform duration-300 transform w-full md:w-1/2 overflow-y-auto",
    menuOpen: "translate-x-0",
    menuClosed: "translate-x-full",
    overlay: "fixed inset-0 bg-textGrey bg-opacity-50 z-20 md:block hidden",
    ul: "mt-8 space-y-6 text-lg md:text-xl",
  };
  