interface CardEntry {
  src: string;
  alt: string;
}

interface Props {
  card: CardEntry;
}

export const Card = ({ card }: Props) => (
  <img
    className="w-full h-full object-cover block"
    src={card.src}
    alt={card.alt}
  />
);
