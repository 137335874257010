import { CrochetIcon } from 'components/Logo/CrochetIcon';

export const Logo = () => {
  return (
      <div className="flex items-center">
        <CrochetIcon />
        <span className="text-[17px] md:text-xl font-600 ml-2 lg:ml-4 text-primary leading-9 lg:text-3xl">
          Crochet App
        </span>
      </div>
  );
};
