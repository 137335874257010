const formStyles = {
    input:
      "w-full px-4 py-3 border border-borderPrimary placeholder-textSecondaryGrey placeholder:text-sm rounded-lg focus:outline-none",
    textarea:
      "w-full px-4 py-3 border border-borderPrimary placeholder-textSecondaryGrey placeholder:text-sm text-lg rounded-lg focus:outline-none resize-none h-24 md:h-28 lg:h-32",
    button:
      "w-full bg-buttonPrimary text-white py-4 rounded-lg font-semibold text-base hover:bg-[#994fbd] shadow-lg shadow-[#5B1C7929]",
  };
  
  export default formStyles;
  