import { LanguageSwitcher } from "components/LanguageSwitcher";
import { Logo } from "components/Logo";
import { Container } from "react-grid-system";
import MobileMenu from "./MobileMenu";
import { scrollToSection } from "utils/scrollToSection";
import { useTranslation } from "react-i18next";

export const Header = () => {
  const { t } = useTranslation();

  return (
    <header className="pt-4 lg:pt-10 bg-heroBackground">
      <Container>
        <div className="max-w-[1200px] mx-auto flex items-center justify-between">
          <Logo />
          <nav className="hidden lg:flex items-center space-x-10 text-textPrimary">
            <button
              className="text-lg hover:text-hoverNavigation"
              onClick={() => {
                scrollToSection("patterns");
              }}
            >
              {t("header.patterns")}
            </button>
            <button
              className="text-lg hover:text-hoverNavigation"
              onClick={() => {
                scrollToSection("features");
              }}
            >
              {t("header.features")}
            </button>
            <button
              className="text-lg hover:text-hoverNavigation"
              onClick={() => {
                scrollToSection("abbreviation");
              }}
            >
              {t("header.abbreviation")}
            </button>
            <button
              className="text-lg hover:text-hoverNavigation"
              onClick={() => {
                scrollToSection("faq");
              }}
            >
              {t("header.faq")}
            </button>
            <LanguageSwitcher />
          </nav>
          <MobileMenu />
        </div>
      </Container>
    </header>
  );
};
