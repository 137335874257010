export enum Direction {
  Left = "left",
  Right = "right",
}

export enum FAQIconType {
  Plus = "plus",
  Minus = "minus",
}

export enum ContactStatus {
  Success = "success",
  Error = "error",
}

export enum Language {
  English = "en",
  German = "de",
  French = "fr",
  Spanish = "es",
  Portuguese = "pt",
}
