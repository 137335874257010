
export const videos = [
  {
    id: 'Cnj52Vu3-QQ',
    title: 'Start, slip knot | Mastering the Basics: How to Do a Sli...',
    description: 'In crochet, a slip knot is the first step in starting a new project. It\'s a basic knot that you create to secure the yarn to your crochet hook before you begin working your stitches',
  },
  {
    id: '3VRHLNOgBac',
    title: '3 hdc in 1 st | Taking Crochet Further: Creating...',
    description: 'A 3 half double crochet in 1 stitch is a crochet technique where you work 3 half double crochet stitches into the same stitch or space, creating a cluster of 3 half double crochets. ',
  },
  {
    id: 'Qe2sM-kJpe0',
    title: 'mr | Beyond the Basics: The Art of Magic Ring',
    description: 'A magic ring, also known as a magic loop or magic circle, is a crochet technique used to create a tightly closed center when starting a project in the round, such as amigurumi, hats or other circular crochet items',
  },
  {
    id: 'Vyb7vVgPlh4',
    title: '2 dc in 1 st | Taking Crochet Further: Creating 2 Double Crochet Stitches in 1',
    description: 'Two double crochet in 1 stitch is a crochet technique that involves working 2 double crochet stitches into a single stitch from the previous row or round.',
  },
  {
    id: 'oIf5wMuqgQg',
    title: '3 sc in 1 st | Taking Crochet Further: Creating 3 Single Crochet Stitches in 1',
    description: 'A 3 single crochet in 1 stitch is a crochet technique where you work 3 single crochet stitches into the same stitch or space, creating a cluster of 3 single crochets',
  },
  {
    id: '0s6rTz8ojqw',
    title: '2 hdc in 1 st | Taking Crochet Further: Creating 2 Half Double Crochet Stitches in 1',
    description: 'Two half double crochet in 1 stitch is a crochet technique used to increase the stitch count by working 2 half double crochet stitches into a single stitch from the previous row or round.',
  },
  {
    id: 'ZiImjgKUE9A',
    title: '2 sc in 1 st | Taking Crochet Further: Creating 2 Single Crochet Stitches in 1',
    description: 'A 2 single crochet in 1 stitch refers to an increase technique where you work 2 single crochet stitches into the same stitch or space. ',
  },
  {
    id: 'T-4ZeL_tZ_Y',
    title: 'ch | Mastering the Basics: How to Crochet Chain Stitch',
    description: 'Chain stitch is a basic crochet stitch in which yarn is pulled with the crochet hook through a loop on the hook to form a continuous strand of interlocking single loops.',
  },
  {
    id: 'u7u-s1Ax4Vc',
    title: 'dc | Mastering the Basics: How to Do a Double Crochet Stitch',
    description: 'Double crochet is one of the essential basic crochet stitches. The double crochet stitch is taller than some of the other basic crochet stitches like single ',
  }
];
