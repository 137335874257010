import heartImage from 'assets/icon-heart.webp';
import pinImage from 'assets/icon-pin.webp';
import rabbitImage from 'assets/icon-rabbit.webp';
import roundButtonImage from 'assets/icon-round-button.webp';
import squareButtonImage from 'assets/icon-square-button.webp';
import yarnImage from 'assets/icon-yarn.webp';

export const cards = [
  {
    src: squareButtonImage,
    alt: 'Square button blue color',
    background: 'bg-blue',
    border: 'border-blueBorder',
    textColor: 'text-blueCardText',
    titleKey: 'pdf_projects_title',
    textKey: 'pdf_projects_text',
  },
  {
    src: rabbitImage,
    alt: 'Plush toy rabbit',
    background: 'bg-yellow',
    border: 'border-yellowBorder',
    textColor: 'text-yellowCardText',
    titleKey: 'crochet_app_patterns_title',
    textKey: 'crochet_app_patterns_text',
  },
  {
    src: heartImage,
    alt: 'Knitted heart green color',
    background: 'bg-green',
    border: 'border-greenBorder',
    textColor: 'text-greenCardText',
    titleKey: 'row_counter_title',
    textKey: 'row_counter_text',
  },
  {
    src: yarnImage,
    alt: 'Purple yarn',
    background: 'bg-purple',
    border: 'border-purpleBorder',
    textColor: 'text-purpleCardText',
    titleKey: 'yarn_inventory_title',
    textKey: 'yarn_inventory_text',
  },
  {
    src: roundButtonImage,
    alt: 'Rounded button pink color',
    background: 'bg-pink',
    border: 'border-pinkBorder',
    textColor: 'text-pinkCardText',
    titleKey: 'personal_shopping_list_title',
    textKey: 'personal_shopping_list_text',
  },
  {
    src: pinImage,
    alt: 'Grey pin',
    background: 'bg-grey',
    border: 'border-greyBorder',
    textColor: 'text-greyCardText',
    titleKey: 'abbreviations_tools_title',
    textKey: 'abbreviations_tools_text',
  },
];
