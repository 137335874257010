import { LanguageSwitcher } from "components/LanguageSwitcher";
import { Logo } from "components/Logo";
import { useEffect, useState } from "react";
import { scrollToSection } from "utils/scrollToSection";
import { mobileMenuStyles } from "./mobileMenuStyles";
import { useTranslation } from "react-i18next";

const MobileMenu = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenuOpen = () => setIsOpen(!isOpen);

  const {
    button,
    buttonOpen,
    spanBase,
    spanOpenPrimary,
    spanOpenSecondary,
    spanClosed,
    menuContainer,
    menuOpen,
    menuClosed,
    overlay,
    ul,
  } = mobileMenuStyles;

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
      return;
    }

    document.body.style.overflow = "auto";
  
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  return (
    <div className="lg:hidden">
      <button
        onClick={toggleMenuOpen}
        aria-label="Toggle mobile menu"
        className={`${button} ${isOpen ? buttonOpen : ""}`}
      >
        <span
          className={`${spanBase} ${
            isOpen ? spanOpenPrimary : spanClosed
          }`}
        />
        <span
          className={`${spanBase} ${
            isOpen ? spanOpenSecondary : spanClosed
          }`}
        />
      </button>
      <div
        className={`${menuContainer} ${isOpen ? menuOpen : menuClosed}`}
      >
        <div className="p-6">
          <div className="md:hidden">
            <Logo />
          </div>
          <ul className={ul}>
            <li>
              <button
                onClick={() => {
                  scrollToSection("download");
                  toggleMenuOpen();
                }}
              >
                {t("header.download")}
              </button>
            </li>
            <li>
              <button
                onClick={() => {
                  scrollToSection("patterns");
                  toggleMenuOpen();
                }}
              >
                {t("header.patterns")}
              </button>
            </li>
            <li>
              <button
                onClick={() => {
                  scrollToSection("features");
                  toggleMenuOpen();
                }}
              >
                {t("header.features")}
              </button>
            </li>
            <li>
              <button
                onClick={() => {
                  scrollToSection("abbreviation");
                  toggleMenuOpen();
                }}
              >
                {t("header.abbreviation")}
              </button>
            </li>
            <li>
              <button
                onClick={() => {
                  scrollToSection("faq");
                  toggleMenuOpen();
                }}
              >
                {t("header.faq")}
              </button>
            </li>
            <li>
              <LanguageSwitcher isMobileMenuOpen={isOpen} />
            </li>
          </ul>
        </div>
      </div>
      {isOpen && (
        <div
          onClick={toggleMenuOpen}
          className={overlay}
        ></div>
      )}
    </div>
  );
};

export default MobileMenu;
