import { useState } from "react";
import FaqIcon from "./FaqIcon";
import { ContentContainer } from "components/Container";
import { FAQIconType } from "types";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

interface FAQItem {
  question: string;
  answer: string;
}

export const FAQ = () => {
  const { t } = useTranslation();
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const toggleFAQ = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const faqContent = t("faq.questions", { returnObjects: true });

  return (
    <section id="faq" className="mt-20 lg:mt-36 scroll-mt-10">
      <ContentContainer>
        <>
          <h2 className="max-w-78 md:max-w-full title-text-style">
            {t("faq.title")}
          </h2>

          <p className="subtitle-text-style max-w-812px mt-5 mb-8">
            {t("faq.subtitle")}
          </p>

          {Object.values(faqContent).map((item: FAQItem, index: number) => (
            <div
              key={item.question}
              className="border-b border-borderPrimary py-8 last:border-0"
            >
              <div
                className="flex justify-between items-center"
                onClick={() => toggleFAQ(index)}
              >
                <h2 className="text-2xl lg:text-[26px] font-medium text-textPrimary">
                  {item.question}
                </h2>
                <span className="ml-4">
                  {openIndex === index ? (
                    <FaqIcon type={FAQIconType.Minus} />
                  ) : (
                    <FaqIcon type={FAQIconType.Plus} />
                  )}
                </span>
              </div>
              {openIndex === index && (
                <div className="mt-4 subtitle-text-style w-11/12 ">
                  {parse(item.answer)}
                </div>
              )}
            </div>
          ))}
        </>
      </ContentContainer>
    </section>
  );
};
