import { ContentContainer } from "components/Container";
import { cards } from "data/patternCards";
import { Card } from "./Card";
import { useTranslation } from "react-i18next";

export const PatternCards = () => {
  const { t } = useTranslation();

  return (
    <section id="patterns" className="mt-20 lg:mt-36 scroll-mt-10">
      <ContentContainer>
        <h2 className="max-w-78 md:max-w-full title-text-style">
          {t("patterns.title")}
        </h2>
        <p className="subtitle-text-style max-w-812px mt-5 lg:mt-6">
          {t("patterns.description")}
        </p>
        <div className="pattern-block">
          {cards.map((card) => (
            <Card key={card.src} card={card} />
          ))}
        </div>
      </ContentContainer>
    </section>
  );
};
