import React from "react";
import { ContactStatus } from "types";

interface ContactIconProps {
    status: ContactStatus;
  }

const ContactIcon: React.FC<ContactIconProps> = ({ status }) => {
  return (
    <svg
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {status === ContactStatus.Success ? (
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.18269 15.2404L2.93269 19.0673L6.75962 19.8173L10 22L13.2404 19.8173L17.0673 19.0673L17.8173 15.2404L20 12L17.8173 8.75962L17.0673 4.93269L13.2404 4.18269L10 2L6.75962 4.18269L2.93269 4.93269L2.18269 8.75962L0 12L2.18269 15.2404ZM8.85107 15.6522L14.6157 10.1527L13.4652 8.94682L8.85205 13.3478L6.53577 11.1343L5.38429 12.3392L8.85107 15.6522Z"
          fill="#A462C4"
        />
      ) : (
        <path
          d="M10.0003 20.3332C5.39783 20.3332 1.66699 16.6023 1.66699 11.9998C1.66699 7.39734 5.39783 3.6665 10.0003 3.6665C14.6028 3.6665 18.3337 7.39734 18.3337 11.9998C18.3337 16.6023 14.6028 20.3332 10.0003 20.3332ZM9.16699 14.4998V16.1665H10.8337V14.4998H9.16699ZM9.16699 7.83317V12.8332H10.8337V7.83317H9.16699Z"
          fill="#E6483D"
        />
      )}
    </svg>
  );
};

export default ContactIcon;
