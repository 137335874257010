import { useState } from "react";
import { ContentContainer } from "components/Container";
import { Col, Row } from "react-grid-system";
import sharePatternImage from "assets/sharePattern.webp";
import formStyles from "./formStyles";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ContactIcon from "./ContactIcon";
import { sendEmail } from "utils/emailService";
import { ContactStatus } from "types";
import { useTranslation } from "react-i18next";

export const ContactForm = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    await sendEmail({
      formData,
      setFormData,
      successIcon: <ContactIcon status={ContactStatus.Success} />,
      errorIcon: <ContactIcon status={ContactStatus.Error} />,
    });
  };

  return (
    <section className="mt-20 lg:mt-36">
      <ContentContainer>
        <Row>
          <Col xs={12}>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-10">
              <div className="relative flex h-full lg:items-center justify-start mb-4">
                <img
                  className="max-w-full max-h-full lg:h-[80%] xl:h-full"
                  src={sharePatternImage}
                  alt="Mobile with crochet app on screen"
                />
              </div>
              <div className="flex flex-col h-full pt-3 pb-3">
                <h2 className="md:max-w-full title-text-style">
                  {t("contact_form.share_pattern")}
                </h2>
                <p className="subtitle-text-style mt-6 mb-12 ">
                  {t("contact_form.contact_email")}
                </p>
                <form className="space-y-6" onSubmit={handleSubmit}>
                  <div>
                    <label className="block text-textPrimary mb-2">
                      {t("contact_form.name_label")}
                    </label>
                    <input
                      type="text"
                      name="name"
                      placeholder={t("contact_form.name_placeholder")}
                      className={formStyles.input}
                      onChange={handleInputChange}
                      value={formData.name}
                    />
                  </div>
                  <div>
                    <label className="block text-textPrimary mb-2">
                      {t("contact_form.email_label")}
                    </label>
                    <input
                      type="email"
                      name="email"
                      placeholder={t("contact_form.email_placeholder")}
                      className={formStyles.input}
                      onChange={handleInputChange}
                      value={formData.email}
                    />
                  </div>
                  <div>
                    <label className="block text-textPrimary mb-2">
                      {t("contact_form.message_label")}
                    </label>
                    <textarea
                      name="message"
                      placeholder={t("contact_form.message_placeholder")}
                      className={formStyles.textarea}
                      rows={4}
                      onChange={handleInputChange}
                      value={formData.message}
                    ></textarea>
                  </div>
                  <div>
                    <button type="submit" className={formStyles.button}>
                      {t("contact_form.send_button")}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Col>
        </Row>
      </ContentContainer>
      <ToastContainer
        className="custom-toast-container"
        hideProgressBar={true}
      />
    </section>
  );
};
